<template>
    <div id="contact">
        <h2>Contact</h2>
        <contact-form></contact-form>
    </div>
</template>
<script>
// @ is an alias to /src
import ContactForm from '../components/ContactForm.vue'

export default {
  name: 'Contact',
  components: {
    ContactForm
  }
}
</script>