<template>
    <form action="" method="post" class='mt-5' v-on:submit="submitForm">
        <div v-if="done">
            <p class="h2">Votre message a bien été envoyé</p>
            <router-link to="/" class='btn btn-back'>
                <font-awesome-icon :icon="['fas', 'arrow-circle-left']" /> Retour
            </router-link>
        </div>
        <div v-else>
            <div class="form-group">
                <label for="email">Votre e-mail</label>
                <input type="email" class="form-control" name="email" id="email" placeholder="example@domaine.com" v-model="email" required>
            </div>
            <div class="form-group">
                <label for="sujet">Sujet</label>
                <input type="text" class="form-control" name="sujet" id="sujet" placeholder="A propos de..." v-model="sujet" required>
            </div>
            <div class="form-group">
                <label for="message">Votre message</label>
                <textarea class="form-control" name="message" id="message" v-model="message" required placeholder="Votre message"></textarea>
            </div>
            <vue-recaptcha @verify="onVerify" sitekey="6Lc-0fIZAAAAABF0jGA1wfdRitaYlYJPHrCJ5UnW"></vue-recaptcha>
            <router-link to="/" class='btn btn-back'>
                <font-awesome-icon :icon="['fas', 'arrow-circle-left']" /> Retour
            </router-link>
            <button type="submit" class='btn btn-light mt-5 ml-2'>
                <font-awesome-icon :icon="['far', 'envelope']" /> Envoyer
            </button>
        </div>
    </form>
</template>
<script>
import VueRecaptcha from 'vue-recaptcha';
import axios from 'axios';
// @ is an alias to /src
export default {
    name: 'ContactForm',
    components: { VueRecaptcha },
    data : function(){
        return {
            email:"",
            sujet:"",
            message:"",
            verified:false,
            done:false
        }
    },
    methods: {
        submitForm : function(ev) {
            ev.preventDefault();
            var mail = {
                email : this.email,
                sujet : this.sujet,
                message : this.message,
            };
            if (this.verified) {
                var that = this;
                axios.post("/api/index.php", mail).then(function(){
                    that.done = true;
                });
            } else {
                alert("Merci de remplir tous les champs et de cocher le captcha");
            }
        },
        onVerify: function(response) {
            if (response) {
                this.verified = true;
            }
        }
    }
}
</script>

<style>
textarea {
    min-height: 150px;
}
</style>